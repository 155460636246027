<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="企业名称：">
        <el-input placeholder="请输入企业名称" v-model.trim="query.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="认证状态：">
        <el-select v-model="query.authenticationStatus" clearable>
          <el-option
            v-for="i in AuthStatusList"
            :key="i.value"
            :label="i.name"
            :value="i.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建人手机号：">
        <el-input placeholder="请输入创建人手机号" v-model.trim="query.createUserPhone" clearable></el-input>
      </el-form-item>
      <el-form-item label="企业编号：">
        <el-input placeholder="请输入企业编号" v-model.trim="query.code" clearable></el-input>
      </el-form-item>
      <el-form-item label="创建起始时间：">
        <el-date-picker v-model="query.createStartTime" type="datetime" value-format="timestamp" placeholder="请选择创建起始时间" popper-class="xzpt" clearable></el-date-picker>
      </el-form-item>
      <el-form-item label="创建结束时间：">
        <el-date-picker v-model="query.createEndTime" type="datetime" value-format="timestamp" placeholder="请选择创建结束时间" popper-class="xzpt" clearable></el-date-picker>
      </el-form-item>
      <el-form-item label="认证起始时间：">
        <el-date-picker v-model="query.authenticationStartTime" type="datetime" value-format="timestamp" placeholder="请选择认证起始时间" popper-class="xzpt" clearable></el-date-picker>
      </el-form-item>
      <el-form-item label="认证结束时间：">
        <el-date-picker v-model="query.authenticationEndTime" type="datetime" value-format="timestamp" placeholder="请选择认证结束时间" popper-class="xzpt" clearable></el-date-picker>
      </el-form-item>
      <el-form-item label="创建人姓名：">
        <el-input placeholder="请输入创建人姓名" v-model.trim="query.createUserName" clearable></el-input>
      </el-form-item>
      <el-form-item label="开通服务：">
        <el-select v-model="query.activateServices" multiple collapse-tags clearable @change="handleChange">
          <el-option
            v-for="i in [
              { label: 'SCA', value: 'SCA' },
              { label: '医修保', value: '医修保' },
            ]"
            :key="i.value"
            :label="i.label"
            :value="i.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="经营类型：">
        <el-select v-model="query.businessTypes" multiple collapse-tags clearable @change="handleChange">
          <el-option
            v-for="i in businessOptions"
            :key="i.value"
            :label="i.name"
            :value="i.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { getTenantList } from '@/api/tenant';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat';
import registerSourceEnum from '@/model/xzpt/registerSource';
import AuthEnum, { AuthStatusList } from '@/model/xzpt/auth';
import BusinessTypeEnum, { BusinessTypes } from '@/model/xzpt/businessTypes';

export default {
  name: 'TENANT_INDEX',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      query: {
        name: '',
        authenticationStatus: '',
        createUserPhone: '',
        code: '',
        createStartTime: '',
        createEndTime: '',
        authenticationStartTime: '',
        authenticationEndTime: '',
        createUserName: '',
        activateServices: [],
        businessTypes: [],
      },
      AuthStatusList,
      businessOptions: BusinessTypes,
      tenantList: [],
      columnList: [
        {
          tooltip: true,
          label: '创建应用端',
          minWidth: '100px',
          prop: 'registerSource',
          format: (v) => {
            return registerSourceEnum[v];
          },
        },
        {
          tooltip: true,
          label: '企业编号',
          minWidth: '150px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '企业名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '经营类型',
          minWidth: '150px',
          prop: 'businessType',
          format: (v) => {
            if (v) {
              const valArr = v.split(',');

              return valArr.map(v => BusinessTypeEnum[v]).join(',');
            }
          },
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '150px',
          prop: 'createTime',
          sortable: true,
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '认证状态',
          minWidth: '150px',
          prop: 'authenticationStatus',
          format: (v) => {
            return AuthEnum[v];
          },
        },
        {
          tooltip: true,
          label: '创建人姓名',
          minWidth: '150px',
          prop: 'applicantName',
        },
        {
          tooltip: true,
          label: '创建人手机号',
          minWidth: '150px',
          prop: 'applicantPhoneNumber',
        },
        {
          tooltip: true,
          label: '开通服务',
          minWidth: '150px',
          prop: 'activateService',
        },
        {
          label: '操作',
          minWidth: '200px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                class="cursor-pointer text-primary margin-left"
                onClick={this.handleActionClick.bind(this, row, 'check')}
              >
                查看
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    handleActionClick ({ id }, action) {
      if (action === 'check') {
        this.$router.push({
          name: 'XZPT_Company_Detail',
          query: {
            id,
          },
        });
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        phoneNumber: '',
        comparisonOperator: '',
        tenantNum: '',
        registerSource: '',
        registerType: '',
        startTime: '',
        endTime: '',
      };
      this.handleSearch();
    },
    getTableList () {
      const query = {};

      for (let k in this.query) {
        if (this.query[k]) {
          query[k] = this.query[k];
        }
      }

      let param = {
        ...query,
        orderBy: [this.createTimeSortInfo],
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      };

      getTenantList(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },

    handleChange () {
      this.pagination.currentPage = 1;

      this.getTableList();
    },
  },
};
</script>
